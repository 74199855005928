<template>
  <div class="wrapper-lg" v-if="!loading">
    <div class="row">
      <div class="col" style="max-width: 200px">
        <erp-s-field
            class="font-bold"
            view="lr"
            label="Solicitação:"
            label-width="60px"
        >
          <span class="font-normal font-11">{{solicitacao.id}}</span>
        </erp-s-field>
      </div>

      <div class="col">
        <erp-s-field
            class="font-bold"
            view="lr"
            :label="!historico.vistoriador ? 'Reboquista:' : 'Vistoriador:'"
            label-width="60px"
        >
          <span class="font-normal font-11" v-if="!historico.vistoriador">{{historico.reboquista.nome}} - {{historico.reboquista.documento}}</span>
          <span class="font-normal font-11" v-else>{{historico.checklist.responsavel.name}} - ID #{{historico.checklist.responsavel.id}}</span>
        </erp-s-field>
      </div>
    </div>

    <div class="lista-fotos-remocao m-t row">
      <div v-for="foto in fotos" :key="foto.id" class="foto">
        <a @click="download(foto)" target="_blank"><img :src="foto.versions.thumb.url" width="180" /></a>
        <div class="divergencia text-negative" v-if="foto.divergente">
          <strong>Divergência:</strong> {{foto.observacao }}
        </div>
        <div class="font-thin small m-t-xs">Tirada em {{foto.createdAt.date|formatDate}}</div>
        <!-- <div v-if="vistoriador && foto.obsVistoriador">{{foto.obsVistoriador}}</div> @TODO -->
      </div>
    </div>

    <div>
      <div v-for="assinatura in assinaturas" :key="assinatura.id">
        <u-btn icon="file-signature" icon-type="fa" dense flat @click="download(assinatura)" :label="getAsssinaturaLabel(assinatura)" no-caps></u-btn>
      </div>
    </div>
  </div>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {downloadArquivoChecklist} from '@/domain/remocao/services'
import WindowLoading from '../../../layout/window/Loading'
import {ErpSField} from 'uloc-vue-plugin-erp'

export default {
  name: 'FotosRemocao',
  components: {WindowLoading, ErpSField},
  props: ['historico', 'solicitacao'],
  data () {
    return {
      loading: false,
      errors: null,
      status: null,
    }
  },
  mounted () {
  },
  computed: {
    fotos () {
      let array = this.historico.vistoriador ? [2] : [1]
      return this.historico.checklist.arquivos.filter(f => array.includes(Number(f.tipo)))
    },
    assinaturas () {
      let array = this.historico.vistoriador ? [101] : [100, 102]
      return this.historico.checklist.arquivos.filter(f => array.includes(Number(f.tipo)))
    }
  },
  methods: {
    getAsssinaturaLabel (a) {
      switch (Number(a.tipo)) {
        case 100:
          return 'Visualizar assinatura do reboquista'
        case 101:
          return 'Visualizar assinatura do vistoriador'
        case 102:
          return 'Visualizar assinatura do responsável'
      }
    },
    download (arquivo) {
      downloadArquivoChecklist(arquivo.id)
          .then(response => {
            arquivo.downloading = true
            const link = document.createElement('a')
            link.href = response.data.url
            link.target = '_blank'
            document.body.appendChild(link)
            link.click();
            window.setTimeout(() => {
              document.body.removeChild(link)
              arquivo.downloading = false
            }, 100)
            // window.open(response.data.url)
          })
          .catch(error => {
            arquivo.downloading = false
            this.alertApiError(error)
          })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .lista-fotos-remocao {
    flex-wrap wrap !important

    .foto {
      flex-wrap wrap
      margin-right 20px
      margin-bottom 20px
      max-width 180px

      img {
        min-width 180px
        cursor zoom-in
      }

      .divergencia {
        font-size 11px
      }
    }
  }
</style>
